<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="py-4">
      <b-card header="Bulk Update" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-form @submit="onSubmit" id="form-update" @reset="onReset">
          <b-row>
            <b-col lg="6">
              <b-row>
                <b-form-group class="col">
                  <b-form-file v-model="file_csv" accept=".csv, .xlsx" placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..." required>
                  </b-form-file>
                </b-form-group>
                <b-form-group class="col-lg-3">
                  <b-link class="btn btn-outline" :href="URL_STATIC.TEMPLATE_BULK_UPDATE" style="color: #28a745; border-color: #28a745">
                    Template <i class="fa fa-file-excel-o"></i>
                  </b-link>
                </b-form-group>
              </b-row>
            </b-col>
            <b-col>
              <li v-for="(data) in statusUpload" :key="data.key">
                <span v-if="data.error" class="bg-danger">
                  <b>{{ data.id  }}</b> {{ data.message }}
                </span>
                <span v-else class="bg-success">
                  <b>{{ data.id  }}</b> {{ data.message }}
                </span>
              </li>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-card header="Review Data" header-bg-variant="primary" header-tag="h5" header-class="font-weight-bold">
        <b-row class="pb-2">
          <b-col lg="6">
            <b-button variant="primary" type="submit" form="form-update">Submit</b-button>
          </b-col>
          <b-col lg="6" class="text-right">
            <b-button type="reset" variant="link" class="text-danger" form="form-update">Reset</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-2" cols="12" v-if="isProcessUpdate">
            <b-progress :value="progressBar" :max="maxProgressBar" animated height="5px"></b-progress>
          </b-col>
        </b-row>
        <div class="table-wrap">
          <b-table id="table-bulk-update" show-empty striped hover sticky-header="600px" :items="csvData"
            style="white-space: unset; min-height: 500px; padding-bottom: 200px;">
            <template #cell(action)="data">
              <i class="fa fa-trash fa-2 text text-danger" style="cursor: pointer; font-size: 24px;"
                @click="handleDelete(data)"></i>
            </template>

            <template #cell(id)="data">
              <router-link target="_blank" :to="{ name: DetailItems.name, params: { id: data.item.id } }">
                {{ data.item.id }}
              </router-link>
            </template>
            <template #cell(name)="data">
              <b-form-input v-model="csvData[data.index].name" style="width:200px" />
            </template>
            <template #cell(edition_code)="data">
              <b-form-input v-model="csvData[data.index].edition_code" style="width:200px" />
            </template>
            <template #cell(slug)="data">
              <b-form-input v-model="csvData[data.index].slug" style="width:max-content" />
            </template>
            <template #cell(issue_number)="data">
              <b-form-input v-model="csvData[data.index].issue_number" style="width:max-content" />
            </template>
            <template #cell(gtin13)="data">
              <b-form-input v-model="csvData[data.index].gtin13" style="width:max-content" />
            </template>
            <template #cell(gtin14)="data">
              <b-form-input v-model="csvData[data.index].gtin14" style="width:max-content" />
            </template>
            <template #cell(printed_price)="data">
              <b-form-input v-model="csvData[data.index].printed_price" type="number" style="width:200px" />
            </template>
            <template #cell(ios_tier)="data">
              <multiselect v-model="csvData[data.index].ios_tier" label="tier_code" track-by="tier_code" placeholder="Ios Tier"
                open-direction="bottom" :options="iosTierSearchOptions" :searchable="true" :loading="isIosTierSearch"
                :close-on-select="true" :multiple="false" @search-change="iosTierFind" style="min-width: 250px;" v-if="data.item.price_usd">
              </multiselect>
            </template>
            <template #cell(android_tier)="data">
              <multiselect v-model="csvData[data.index].android_tier" label="tier_code" track-by="tier_code"
                placeholder="Android Tier" open-direction="bottom" :options="androidTierSearchOptions" :searchable="true"
                :loading="isAndroidTierSearch" :close-on-select="true" :multiple="false" @search-change="androidTierFind"
                style="min-width: 250px;" v-if="data.item.price_usd">
              </multiselect>
            </template>
            <template #cell(description)="data">
              <b-form-textarea v-model="csvData[data.index].description" style="width:500px"></b-form-textarea>
            </template>
            <template #cell(languages)="data">
              <multiselect placeholder="Enter languages" open-direction="bottom" v-model="csvData[data.index].languages"
                label="name" track-by="name" :custom-label="nameWithISO" :options="languagesSearchOptions"
                :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true">
              </multiselect>
            </template>
            <template #cell(countries)="data">
              <multiselect placeholder="Enter Countries" open-direction="bottom" v-model="csvData[data.index].countries"
                label="name" track-by="name" :custom-label="nameWithISO" :options="countriesSearchOptions"
                :searchable="true" :close-on-select="true" :options-limit="10" :multiple="true">
              </multiselect>
            </template>
            <template #cell(author)="data">
              <multiselect v-model="csvData[data.index].author" label="name" track-by="name" placeholder="Enter Author"
                open-direction="bottom" :options="authorSearchOptions" :loading="isAuthorSearch" :searchable="true"
                :close-on-select="true" :options-limit="10" :multiple="true" @search-change="authorFind"
                style="min-width: 200px;">
              </multiselect>
            </template>
            <template #cell(categories)="data">
              <multiselect v-model="csvData[data.index].categories" label="name" track-by="name"
                placeholder="Enter Catddegories" open-direction="bottom" :options="categoriesSearchOptions"
                :loading="isCategoriesSearch" :searchable="true" :close-on-select="true" :options-limit="10"
                :multiple="true" @search-change="categoriesFind" style="min-width: 200px;">
              </multiselect>
            </template>
            <template #cell(item_status)="data">
              <b-form-select v-model="csvData[data.index].item_status" :options="itemStatusOptions"
                style="width:max-content"></b-form-select>
            </template>
            <template #cell(printed_currency_code)="data">
              <b-form-select v-model="csvData[data.index].printed_currency_code" :options="currencyOptions"
                style="width:max-content" required></b-form-select>
            </template>
            <template #cell(is_internal_content)="data" class="text-center">
              <b-form-select v-model="csvData[data.index].is_internal_content" :options="classicOptions"
                style="width:max-content" required></b-form-select>
            </template>
            <template #cell(is_active)="data" class="text-center">
              <b-form-select v-model="csvData[data.index].is_active" :options="classicOptions" style="width:max-content"
                required></b-form-select>
            </template>
          </b-table>
        </div>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import XLSX from 'xlsx';
import { DetailItems } from '../../router/content';
import constant from '../../store/constant';

export default {
  data() {
    return {
      DetailItems,
      isAuthorSearch: false,
      isCategoriesSearch: false,
      file_csv: null,
      statusUpload: [],
      csvData: [],
      languagesSearchOptions: [],
      countriesSearchOptions: [],
      authorSearchOptions: [],
      categoriesSearchOptions: [],
      androidTierSearchOptions: [],
      iosTierSearchOptions: [],
      itemStatusOptions: [
        { text: '', value: null },
        ...Object.values(constant.ITEM_STATUS),
      ],
      currencyOptions: [
        { text: '', value: null },
        ...Object.values(constant.PRINTED_PRICE_ITEM),
      ],
      classicOptions: [
        { text: '', value: null },
        { text: 'TRUE', value: true },
        { text: 'FALSE', value: false },
      ],
      progressBar: 20,
      maxProgressBar: 100,
      isProcessUpdate: false,
      URL_STATIC: constant.URL_STATIC,
      isIosTierSearch: false,
      isAndroidTierSearch: false,
    }
  },
  created() {
    this.setCountriesOptions();
    this.languagesSearchOptions = Object.entries(constant.LANGUAGE_CHOICES).map((item) => {
      return {
        iso: item[0].toUpperCase(),
        name: item[1]
      }
    });
    this.searchIosTier({ q: 'c.usd', is_active: true }).then((response) => {
      this.iosTierSearchOptions = response.data.data.rows;
    });
    this.searchAndroidTier({ q: 'c.usd', is_active: true }).then((response) => {
      this.androidTierSearchOptions = response.data.data.rows;
    });
  },
  computed: {
    ...mapState({
      isError: (state) => state.items.isError,
      isLoading: (state) => state.items.isLoading,
      errorMessage: (state) => state.items.errorMessage,
      successMessage: (state) => state.items.successMessage,
    }),
  },
  watch: {
    async file_csv() {
      if (!this.file_csv) {
        this.onReset();
        return;
      }
      this.csvToTable();
    },
  },
  methods: {
    ...mapActions('items', ['updateItems', 'updateSingleOffers', 'getCountries', 'searchCategories']),
    ...mapActions('author', ['searchAuthor']),
    ...mapActions('tiercodeAndroid', {
      searchAndroidTier: 'searchAndroidTier',
    }),
    ...mapActions('tiercodeIOS', {
      searchIosTier: 'searchIosTier',
    }),
    async onSubmit(event) {
      event.preventDefault();
      this.statusUpload = [];
      this.$store.commit(`items/setLoading`, true);
      this.isProcessUpdate = true;

      // set progress bar
      this.progressBar = 0;
      const progressPerData = this.maxProgressBar / this.csvData.length;
      
      for (const data of this.csvData.values()) {
        if (!data.id) {
          this.statusUpload.push({
            key: +new Date(),
            error: true,
            message: 'required id'
          });
          continue;
        }
        this.$store.commit(`items/setLoading`, true);
        const payload = { ...data };
        payload.countries = data.countries?.map(item => item.iso.toLowerCase());
        payload.languages = data.languages?.map(item => item.iso.toLowerCase());
        payload.author_id = data.author?.map(item => item.id);
        payload.categories_id = data.categories?.map(item => item.id);

        Object.keys(payload).forEach((key) => {
          const val = payload[key];
          if ([null, undefined, '', NaN].includes(val)) delete payload[key];
          if (Array.isArray(val) && !val.length) delete payload[key];
        });
        const response = await this.updateItems(payload);
        if (response.error) {
          this.statusUpload.push({
            key: +new Date(),
            id: payload.id,
            name: payload.name,
            ...response
          });
        }
        if (!response.error) {
          this.statusUpload.push({
            key: +new Date(),
            id: payload.id,
            name: payload.name,
            message: 'successfully updated',
          });
          this.csvData = this.csvData.filter(val => val.id != payload.id);
          this.progressBar += progressPerData;
        }
      }
      this.isProcessUpdate = false;
      this.$store.commit(`items/setLoading`, false);
    },
    onReset() {
      this.isProcessUpdate = false;
      this.file_csv = null;
      this.statusUpload = [];
      this.csvData = [];
    },
    handleDelete(data) {
      this.csvData.splice(data.index, 1);
    },
    async validateDataCsv() {
      this.$store.commit(`items/setLoading`, true);
      for (const val of this.csvData) {
        const lang = val.languages?.split('#');
        const countries = val.countries?.split('#');
        const authors = val.author?.split('#');
        const categories = val.categories?.split('#');

        if (lang?.length) {
          val.languages = this.languagesSearchOptions.filter(lg => lang.includes(lg.name.toLowerCase()))
        }
        if (countries?.length) {
          val.countries = this.countriesSearchOptions.filter(cn => countries.includes(cn.name.toLowerCase()))
        }

        val.author = [];
        for (let query of (authors || [])) {
          query = query.toLowerCase();
          const check = this.authorSearchOptions.find(as => as.name.toLowerCase() == query);
          if (check?.name) {
            val.author.push(check);
            continue;
          }

          // query = encodeURIComponent(query);
          const response = await this.searchAuthor({ name: query });
          // query = decodeURIComponent(query);
          const rows = response.data.data?.rows;
          const findAuthor = rows.find(au => au.name.toLowerCase() == query);
          if (findAuthor?.name) {
            this.authorSearchOptions.push(findAuthor);
            val.author.push(findAuthor);
          }
        }
        if (!val.author?.length) delete val.author;

        val.categories = [];
        for (let query of (categories || [])) {
          query = query.toLowerCase();
          const check = this.categoriesSearchOptions.find(as => as.name.toLowerCase() == query);
          if (check?.name) {
            val.categories.push(check);
            continue;
          }

          query = encodeURIComponent(query);
          const response = await this.searchCategories(query);
          query = decodeURIComponent(query);
          const rows = response.data.data?.rows;
          const findCategory = rows.find(ct => ct.name.toLowerCase() == query)
          if (findCategory?.name) {
            this.categoriesSearchOptions.push(findCategory);
            val.categories.push(findCategory)
          }
        }
        if (!val.categories?.length) delete val.categories;

        const checkIosTier = this.iosTierSearchOptions.find(it => it.tier_price == val.price_usd);
        if (checkIosTier) {
          val.ios_tier = checkIosTier;
        } else {
          let iosTier = await this.searchIosTier({ q: val.price_usd, is_active: true });
          iosTier = iosTier.data.data?.rows;
          val.ios_tier = iosTier.find(it => it.tier_code == val.price_usd);
          this.iosTierSearchOptions.push(...iosTier);
        }

        const checkAndrTier = this.androidTierSearchOptions.find(at => at.tier_price == val.price_usd);
        if (checkAndrTier) {
          val.android_tier = checkAndrTier;
        } else {
          let androidTier = await this.searchAndroidTier({ q: val.price_usd, is_active: true });
          androidTier = androidTier.data.data?.rows;
          val.android_tier = androidTier.find(at => at.tier_code == val.price_usd);
          this.androidTierSearchOptions.push(...androidTier);
        }
      }
      this.$store.commit(`items/setLoading`, false);
    },
    csvToTable() {
      const reader = new FileReader()
      reader.readAsBinaryString(this.file_csv)
      reader.onload = () => {
        const workbook = XLSX.read(reader.result, {
          type: 'binary',
          cellDates: true,
        });
        const readData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          raw: true,
          dateNF: 'yyyy-mm-dd hh:mm:ss',
        });

        const boolCheck = {
          'true': true,
          'yes': true,
          '1': true,
          'false': false,
          'no': false,
          '0': false,
        };
        this.csvData = readData.map(
          (item, idx) => {
            const formatData = {
              action: idx,
              ...item
            };
            const isActive = `${formatData.is_active}`.toLowerCase();
            const isInternal = `${formatData.is_internal_content}`.toLowerCase();
            if (formatData.is_active) formatData.is_active = boolCheck[isActive];
            if (formatData.is_internal_content) formatData.is_internal_content = boolCheck[isInternal];
            if (formatData.author) formatData.author = formatData.author ?? null;
            if (formatData.categories) formatData.categories = formatData.categories ?? null;
            if (formatData.countries) formatData.countries = formatData.countries ?? null;
            if (formatData.languages) formatData.languages = formatData.languages ?? null;
            if (formatData.price_usd) {
              formatData.android_tier = {};
              formatData.ios_tier = {};
            }
            return formatData;
          });
        this.validateDataCsv();
      }
    },
    authorFind(query) {
      if (!query) return;

      this.isAuthorSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        let payload = {
          name: query,
          limit: 10,
        };
        this.searchAuthor(payload)
          .then((response) => {
            this.authorSearchOptions = response.data.data.rows;
            this.isAuthorSearch = false;
          })
          .catch(() => {
            this.isAuthorSearch = false;
          });
      }, 600);
    },
    categoriesFind(query) {
      if (!query) return;

      this.isCategoriesSearch = true;
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.searchCategories(query)
          .then((response) => {
            this.categoriesSearchOptions = response.data?.data?.rows;
            this.isCategoriesSearch = false;
          })
          .catch(() => {
            this.isCategoriesSearch = false;
          });
      }, 600);
    },
    iosTierFind(query) {
      if (!query) return;

      this.isIosTierSearch = true;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.searchIosTier({ q: query, is_active: true }).then((response) => {
          this.iosTierSearchOptions = response.data?.data?.rows;
          this.isIosTierSearch = false;
        }).catch(() => {
          this.isIosTierSearch = false;
        });
      }, 600);
    },
    androidTierFind(query) {
      if (!query) return;

      this.isAndroidTierSearch = true;
      clearTimeout(this.debounce);

      this.debounce = setTimeout(() => {
        this.searchAndroidTier({ q: query, is_active: true }).then((response) => {
          this.androidTierSearchOptions = response.data?.data?.rows;
          this.isAndroidTierSearch = false;
        }).catch(() => {
          this.isAndroidTierSearch = false;
        });
      }, 600);
    },
    setCountriesOptions() {
      this.isCountriesSearch = true;
      this.getCountries()
        .then((response) => {
          this.countriesSearchOptions = response.data?.data?.rows;
          this.isCountriesSearch = false;
        })
        .catch(() => {
          this.isCountriesSearch = false;
        });
    },
    nameWithISO({ name, iso }) {
      return `${iso} - ${name}`
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  },
}

</script>

<style>
#table-bulk-update>thead>tr>th,
#table-bulk-update>tbody>tr>td {
  /* background-color: pink; */
  white-space: nowrap;
  /* width: max-content; */
}
</style>